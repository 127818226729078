import {
  BrowserRouter as Router,
  Route,
  Routes,
  Outlet,
  useLocation,
} from "react-router-dom";
import { AuthProvider, useAuth } from "./context/auth-context";
import { useEffect } from "react";
// Components
import LeftBar from "./components/leftBar/LeftBar";
import NavBar from "./components/navbar/Navbar";
import Footer from "./components/footer/Footer";
import AuthCallback from "./components/Auth/auth-callback";
import AuthCallbackLogout from "./components/Auth/auth-callback-logout";
import AuthCallbackSilent from "./components/Auth/auth-callback-silent";
import BlueButtonCallback from "./components/Auth/bluebutton-callback";
// Pages
import Claim from "./pages/claim/Claim";
import LabResult from "./pages/lab/Labresult";
import SearchProvider from "./pages/providerdirectory/Searchprovider";
import AboutMe from "./pages/aboutme/Aboutme";
import SearchFormulary from "./pages/formularydirectory/Searchformulary";
import Changepassword from "./pages/users/Changepassword";
import SignUpForm from "./pages/register/signup";
import ClaimDetail from "./pages/claim/ClaimDetail";
import ProviderDetail from "./pages/providerdirectory/ProviderDetail";
import LabDetail from "./pages/lab/LabDetail";
import PreviousInsurer from "./pages/insurer/PreviousInsurer";
import Login from "./pages/login/Login";
import "./scss/_main.scss";
import "./scss/_theme.scss";
import Forgetpassword from "./pages/users/Forgetpassword";
import PostLogout from "./pages/login/PostLogout";
import CustomLoader from "./components/custom-loader/CustomLoader";
import useAutoLogout from "./hooks/useAutoLogout";

function Layout() {
  const publicRoutes = [
    "/createaccount",
    "/auth-callback",
    "/auth-callback-logout",
    "/auth-callback-silent",
    "/forgotpassword",
    "/login",
    "/post-logout",
  ];
  const { user, userManager, isLoading } = useAuth(); // Get isLoading from context
  const location = useLocation();
  const handleLeftPanel = () => {
    if (document.body.classList.contains("sb-sidenav-toggled")) {
      document.body.classList.toggle("sb-sidenav-toggled");

      localStorage.setItem(
        "sb|sidebar-toggle",
        document.body.classList.contains("sb-sidenav-toggled")
      );
    }
  };
  useAutoLogout();
  useEffect(() => {
    const checkUser = async () => {
      const currentPath = location.pathname;
      const urlSearchParams = new URLSearchParams(location.search);
      console.log("Print Search Parameter", urlSearchParams);
      const codeValue = urlSearchParams.get("code");
      console.log("Code come from the Admin Portal: ", codeValue);
      if (currentPath === "/" && codeValue) {
        localStorage.removeItem("ImpersonateUserID");
        localStorage.setItem("isImpersonate", true);
        localStorage.setItem("ImpersonateUserID", codeValue);
        console.log("Code come from the Admin Portal: ", codeValue);
      } else if (!user && !isLoading && !publicRoutes.includes(currentPath)) {
        try {
          localStorage.setItem("isImpersonate", false);
          localStorage.removeItem("ImpersonateUserID");
          await userManager.signinRedirect({});
        } catch (error) {
          console.error("Redirection to login failed:", error);
          await userManager.signinRedirect({
            state: { returnPath: currentPath },
          });
        }
      }
      if (!user && !isLoading && !publicRoutes.includes(location.pathname)) {
        try {
          const currentURL = new URL(window.location.href);
          const urlSearchParams = new URLSearchParams(currentURL.search);
          const codeValue = urlSearchParams.get("code");
          const ImpersonateUserID = localStorage.getItem("ImpersonateUserID");
          if (ImpersonateUserID && codeValue === ImpersonateUserID) {
            urlSearchParams.set("code", ImpersonateUserID);
            const updatedSearch = urlSearchParams.toString();
            currentURL.search = updatedSearch;
          }
          localStorage.setItem("preAuthURL", currentURL.href);
          await userManager.signinSilent();
        } catch (error) {
          console.error("Redirection to login failed:", error);
          await userManager.signinRedirect({
            state: { returnPath: location.pathname },
          });
        }
      }
    };
    checkUser();
  }, [user, userManager, location.pathname, isLoading]);

  if (isLoading) {
    return <CustomLoader />;
  }

  if (!user && !publicRoutes.includes(location.pathname)) {
    userManager.signinRedirect();
    return <CustomLoader />;
  }

  return (
    <>
      <div id="layoutSidenav">
        <div id="layoutSidenav_nav">
          <LeftBar />
        </div>
        <div id="layoutSidenav_content">
          <NavBar />
          <main>
            <Outlet />
          </main>
          <Footer />
        </div>
        <div className="overlay-backdrop" onClick={handleLeftPanel}></div>
      </div>
    </>
  );
}

function App() {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          {/* Public Routes */}
          <Route path="/createaccount" element={<SignUpForm />} />
          <Route path="/auth-callback" element={<AuthCallback />} />
          <Route
            path="/auth-callback-logout"
            element={<AuthCallbackLogout />}
          />
          <Route
            path="/auth-callback-silent"
            element={<AuthCallbackSilent />}
          />
          <Route path="/bluebutton-callback" element={<BlueButtonCallback />} />
          <Route path="/forgotpassword" element={<Forgetpassword />} />
          <Route path="/login" element={<Login />} />
          <Route path="/post-logout" element={<PostLogout />} />

          {/* Protected Routes */}
          <Route path="/" element={<Layout />}>
            <Route path="/" element={<AboutMe />} />
            <Route path="Claim" element={<Claim />} />
            <Route path="Lab" element={<LabResult />} />
            <Route path="Formulary" element={<SearchFormulary />} />
            <Route path="Provider" element={<SearchProvider />} />
            <Route path="aboutme" element={<AboutMe />} />
            <Route path="changepassword" element={<Changepassword />} />
            <Route path="/claim/:claimId/:source" element={<ClaimDetail />} />
            <Route
              path="/Provider/:practitionerId"
              element={<ProviderDetail />}
            />
            <Route path="/Lab/:labId" element={<LabDetail />} />
            <Route path="PreviousInsurer" element={<PreviousInsurer />} />
          </Route>
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
