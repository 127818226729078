import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import "./PreviousInsurer.scss";
import Select from "react-select";
import PerfectScrollbar from "react-perfect-scrollbar";

const PreviousInsurer = () => {
  const [switchStates, setSwitchStates] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [previousPayerName, setPreviousPayerName] = useState(null);
  const [otherPayerName, setOtherPayerName] = useState("");
  const [selectedPayerId, setSelectedPayerId] = useState(null);

  const payers = [
    { value: "UnitedHealthcare", label: "UnitedHealthcare" },
    { value: "Anthem Blue Cross Blue Shield", label: "Anthem Blue Cross Blue Shield" },
    { value: "Aetna", label: "Aetna" },
    { value: "Cigna", label: "Cigna" },
    { value: "Other", label: "Other" },
  ];

  const handleCheckboxChange = (id) => {
    setSelectedPayerId(id);
    setSwitchStates((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));

    if (!switchStates[id]) {
      handleShowModal();
    }
  };

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleUnsubscribeModal = () => {
    setShowModal(false);
    if (selectedPayerId !== null) {
      setSwitchStates((prevState) => ({
        ...prevState,
        [selectedPayerId]: false,
      }));
      setSelectedPayerId(null);
    }
  };

  const handleConfirm = () => {
    handleUnsubscribeModal();
    // Add any additional logic needed
  };

  const handlePayerChange = (selectedOption) => {
    setPreviousPayerName(selectedOption);
    if (selectedOption?.value !== "Other") {
      setOtherPayerName("");
    }
  };

  const handleConnectToPayer = () => {
    handleShowModal();
    // Add any additional logic needed
  };

  const headers = ["Patient Id", "Payer Name", "Connected Date", "Access"];
  const data = [
    { id: "12345", payerName: "Health Insurance Co", connectedDate: "2023-08-15" },
    { id: "67890", payerName: "Care Solutions", connectedDate: "2023-07-22" },
    { id: "54321", payerName: "Medicare", connectedDate: "2023-09-01" }
  ];

  return (
    <div className="main-content">
      <div className="content-header">
        <h1 className="page-header">Previous Insurer</h1>
      </div>
      <div className="card">
        <div className="card-body">
          <div className="card-form form-content">
            <div className="row">
              <div className="col-sm-6 col-md-6 col-xl-4 col-xxl-3 mb-4 mb-sm-0">
                <label htmlFor="previousPayer" className="d-flex form-label">
                  Previous Payer
                </label>
                <div className="form-field position-relative custom-filter-select h-48">
                  <Select
                    id="previousPayer"
                    className="h-48 cursor-pointer"
                    value={previousPayerName}
                    onChange={handlePayerChange}
                    options={payers}
                    placeholder="Select"
                    isSearchable
                    isClearable
                  />
                </div>
              </div>
              {previousPayerName?.value === "Other" && (
                <div className="col-sm-6 col-md-6 col-xl-4 col-xxl-3 mb-4 mb-sm-0">
                  <label htmlFor="previousPayerName" className="d-flex form-label">
                    Others
                  </label>
                  <div className="form-field">
                    <input
                      id="previousPayerName"
                      type="text"
                      className="form-control h-48"
                      value={otherPayerName}
                      onChange={(e) => setOtherPayerName(e.target.value)}
                      placeholder="Enter previous payer name"
                    />
                  </div>
                </div>
              )}
              <div className="d-flex align-items-sm-end justify-content-center col-12 col-sm-auto">
                <button
                  type="button"
                  className="btn btn-primary h-48"
                  onClick={handleConnectToPayer}
                >
                  <span>Connect to previous payer</span>
                </button>
              </div>
            </div>

          </div>
          <div className="card-content">
            <div className="form-content mb-4">
              <div className="row">
                <div className="col-sm-6 col-md-6 col-xl-4 col-xxl-3 mb-4 mb-sm-0">
                  <label htmlFor="SearchLab" className="d-flex form-label">
                    Search Previous Insurer
                  </label>

                  <div className="form-field position-relative">
                    <input
                      id="SearchLab"
                      type="text"
                      className="form-control"
                      placeholder="Search"
                    />
                    <span className="field-icon position-absolute top-50 translate-middle-y">
                      <i className="fh_search"></i>
                    </span>
                  </div>
                </div>
                <div className="d-flex align-items-sm-end justify-content-center col-12 col-sm-auto">
                  <button
                    type="button"
                    className="btn btn-primary h-48 me-3"
                  >
                    <span>Search</span>
                  </button>
                  <button
                    type="button"
                    className="btn btn-border-primary h-48  me-3"
                  >
                    <span>Clear</span>
                  </button>
                </div>
              </div>
            </div>
            <PerfectScrollbar className="table-responsive custom-main-table insurer-table">
              <table className="table">
                <thead className="sticky-top z-1">
                  <tr>
                    {headers.map((header, index) => (
                      <th key={index}>{header}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {data.map((item, index) => (
                    <tr key={index}>
                      <td>{item.id}</td>
                      <td>{item.payerName}</td>
                      <td>{item.connectedDate}</td>
                      <td className="ins-switch">
                        <span className="form-check form-switch">
                          <input
                            type="checkbox"
                            className="form-check-input cursor-pointer"
                            role="switch"
                            id={`flexSwitchCheckDefault-${item.id}`}
                            checked={switchStates[item.id] || false}
                            onChange={() => handleCheckboxChange(item.id)}
                          />
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </PerfectScrollbar>
          </div>
        </div>
      </div>

      <Modal
        show={showModal}
        onHide={handleUnsubscribeModal}
        centered
        className="custom-pop-up about-me-popup"
        size="lg"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Redirection</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            This is a secure way to fetch your information from your previous insurance portal.
            You will be redirected to the portal, where you can access your information.
          </p>
          <p>
            If you encounter any issues, please reach out to us at&nbsp;
            <a href="mailto:support@aaneelconnect.com">support@aaneelconnect.com</a>.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleUnsubscribeModal}>
            Not now
          </Button>
          <Button variant="primary" onClick={handleConfirm}>
            Next
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default PreviousInsurer;
